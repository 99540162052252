import React from 'react';
import axios from 'axios';
import './App.css';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import HomePage from "./components/HomePage";
import Products from "./components/Products";
import Purdoux from "./components/Purdoux";
import Medistrom from "./components/Medistrom";
import BestInRest from "./components/BestInRest";
import ProductPage from "./components/ProductPage";
import CommunitySupport from "./components/CommunitySupport";
import AboutUs from "./components/AboutUs";
import manual from "./attachments/Pilot Lite Manual for Europe_Sep 2021.pdf";
import quickstart from "./attachments/Pilot_Lite_Quick_Start_Guide.pdf";
import travelletter from "./attachments/Travel Letter for Medistrom Accessories - May 28 2018 (1).pdf";
import sanitizerinfo from "./attachments/Purdoux Hand Sanitizer Sheet General.pdf";
import privacyPolicy from "./attachments/Privacy Policy 2024.11.15.docx.pdf";
import MediaLibrary from "./components/MediaLibrary";
import MediaBIR from "./components/MediaBIR";
import MediaMedistrom from "./components/MediaMedistrom";
import MediaPurdoux from "./components/MediaPurdoux";
import MediaOther from "./components/MediaOther";

import UnderConstruction from "./components/UnderConstruction";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      countryName: '',
      countryCode: ''
  }
  this.getGeoInfo();
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryName: data.country_name,
            countryCode: data.country_calling_code
        });
    }).catch((error) => {
        console.log(error);
    });
};

  render(){
    //this.getGeoInfo();
    //console.log(this.state.countryName);
    return (


      <div className="App">   
        <Router>
          <NavBar/>
          <Switch>
            <Route exact path="/"> 
                <HomePage/>
            </Route>
            <Route exact path="/community-support">
              <CommunitySupport/>
            </Route>
            <Route exact path="/about-us">
              <AboutUs/>
            </Route>
            <Route exact path="/media-library">
              <MediaLibrary/>
            </Route>
            <Route exact path="/media-library/best-in-rest">
              <MediaBIR/>
            </Route>
            <Route exact path="/media-library/medistrom">
              <MediaMedistrom/>
            </Route>
            <Route exact path="/media-library/purdoux">
              <MediaPurdoux/>
            </Route>
            <Route exact path="/media-library/other">
              <MediaOther/>
            </Route>
            <Route exact path="/products">
              <Products/>
            </Route>
            <Route exact path="/products/purdoux">
              <Purdoux/>
            </Route>
            <Route path="/products/purdoux/:value" component={ProductPage}/>


            <Route exact path="/products/medistrom">
              <Medistrom/>
            </Route>
            <Route path="/products/medistrom/:value" component={ProductPage}/>

            <Route exact path="/products/best-in-rest">
              <BestInRest/>
            </Route>
            <Route path="/products/best-in-rest/:value" component={ProductPage}/>

            <Route exact path="/contact-us">
              <ContactUs/>
            </Route>

            <Route exact path="/pilot_manual">
              <embed src={manual} type="application/pdf" width="100%" height="800px"/>
            </Route>
            <Route exact path="/pilot_quickstart">
              <embed src={quickstart} type="application/pdf" width="100%" height="800px"/>
            </Route>
            <Route exact path="/pilot_travel_letter">
              <embed src={travelletter} type="application/pdf" width="100%" height="800px"/>
            </Route>
            <Route exact path="/sanitizer_info">
              <embed src={sanitizerinfo} type="application/pdf" width="100%" height="800px"/>
            </Route>
            <Route exact path="/privacy_policy">
              <embed src={privacyPolicy} type="application/pdf" width="100%" height="800px"/>
            </Route>
          </Switch>
          <Footer/>
        </Router>

       {/* {this.state.countryName=='Canada'&&<Router>
          <Switch>
            <Route exact path="/">
              <UnderConstruction/>
            </Route>
          </Switch>
        </Router>

        }*/}
       
      </div>
    );
  }
}

export default App;